/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
import eurekaMgrs from '@eureka/ui-managers';
import { SideNavigationItem } from 'src/common/ui5dependencies';
import { SideNavigationSubItem } from 'src/common/ui5dependencies';
import eureka from 'eureka';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { createUseStyles } from 'react-jss';
import {
  checkFeaturetoggles,
  checkGlobalConfigs,
  checkPermissions,
  getURLParam,
} from '../../common/Utils';
import SideNavigationObserver from './SideNavigationObserver';
import { ConfigJson, ConfigComponent, Sidenav, SidenavItem, MicroFrontendProps } from 'src/types';
import { Ui5CustomEvent } from 'src/common/ui5dependencies';
import { useHistory } from 'rgp-ui-lib';
import { useLocation } from 'react-router-dom';

// react-jss style setup
// const disabledColor = 'rgba(205, 206, 207, 0.8)';
// const seletedColor = '#0854a0';
// const enableColor = '#32363a';
const styles = {
  sideNavigation: {
    overflow: 'visible',
    borderRadius: '0 !important',
    boxShadow: 'none',
    '& > div': {
      overflow: 'hidden',
    },
    '--_ui5_list_item_title_size': 'var(--sapFontSize)',
  },
  disabled: {
    // color: disabledColor,
    // backgroundColor: 'transparent !important',
    // borderLeft: '4px solid transparent',
    // cursor: 'initial',
    // '& ui5-icon': {
    //   color: disabledColor,
    // },
    // '& span': {
    //   color: disabledColor,
    // },
  },
  enabled: {
    // borderLeft: '4px solid transparent',
    // // backgroundColor: '#e5f0fa',
    // '& ui5-icon': {
    //   color: enableColor,
    // },
    // '&[selected=true]': {
    //   borderLeft: `4px solid ${seletedColor}`,
    //   '& ui5-icon': {
    //     color: seletedColor,
    //   },
    //   '& span': {
    //     color: seletedColor,
    //   },
    // },
  },
  customized: {
    // '& >span:nth-child(3)': {
    //   position: 'absolute',
    //   right: 0,
    //   height: '48px',
    //   width: '240px',
    //   boxSizing: 'border-box',
    //   '& ui5-icon': {
    //     right: '13px',
    //     top: '16px',
    //     position: 'absolute',
    //   },
    // },
  },
};

const useStyles = createUseStyles(styles);
const { getConfig, getFeatureToggle } = eurekaMgrs.ConfigManager;
const { useTranslation } = eureka.I18nProvider;
const homeDisabled = true;
const SideNaviRelationCached = new Map();

// eslint-disable-next-line no-prototype-builtins
const isDisable = (item: unknown) =>
  item &&
  typeof item === 'object' &&
  Reflect.has(item, 'disabled') &&
  Reflect.get(item, 'disabled');

const walkSidenav = ({
  propName,
  propValue,
  valueName,
  isDisable,
  comp,
  sideNav,
}: {
  propName: string;
  propValue: string;
  valueName: string;
  isDisable: (item: unknown) => boolean;
  comp: ConfigComponent;
  sideNav: number;
}) => {
  const m = sideNav;
  const nav = comp?.config?.sidenav?.[m];
  if (nav?.[propName] && nav[propName] === propValue) {
    return {
      appName: comp.config.app,
      [valueName]: nav[valueName],
      disabled: isDisable(nav),
    };
  } else if (nav?.items && Array.isArray(nav.items) && nav.items.length) {
    for (let n = 0; n < nav.items.length; n++) {
      const item = nav.items[n];
      if (
        item[propName] &&
        (item[propName] === propValue ||
          (propName === 'router' &&
            item['subRouters'] &&
            item['subRouters'].filter((pattern) => {
              const regex = new RegExp(pattern.replace(/\*/g, '.*?'));
              return regex.test(propValue);
            }).length > 0))
      ) {
        return {
          appName: comp.config.app,
          [valueName]: item[valueName],
          disabled: isDisable(item),
        };
      }
    }
  } else if (
    comp.config.routers.includes(propValue) &&
    !['/kpi-library', '/next-best-actions-config'].includes(propValue)
  ) {
    const sidenav = comp?.config?.sidenav;
    let foundId = '';
    if (sidenav && sidenav.length > 0) {
      const res = sidenav.find((item) => item[propName] === propValue);
      res && (foundId = res[valueName]);
    }
    return {
      appName: comp.config.app,
      [valueName]: foundId || nav?.[valueName],
      disabled: isDisable(nav),
    };
  }
};

const getConfigValueByProp = (
  config: ConfigJson,
  propName: string,
  propValue: string,
  valueName: string,
) => {
  const valueKey = `propName-${propName}-propValue-${propValue}-valueName-${valueName}`;
  const retValue = SideNaviRelationCached.get(valueKey);
  if (retValue) {
    // get from cache if already stored
    return retValue;
  }
  for (let i = 0; i < config?.components?.length; i++) {
    const comp = config?.components[i];
    if (comp?.config?.sidenav?.length) {
      for (let m = 0; m < comp.config.sidenav.length; m++) {
        const retValue = walkSidenav({
          propName,
          propValue,
          valueName,
          isDisable,
          comp,
          sideNav: m,
        });
        if (retValue) {
          // store value and cache it
          SideNaviRelationCached.set(valueKey, retValue);
          return retValue;
        }
      }
    }
  }
  return null;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Pick<MicroFrontendProps, 'history' | 'config' | 'match'>;

export const SidePanel: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { showMenu } = props.common;
  const [selectedId, setSelectedId] = useState('home');
  const [currentUserPermissions, setCurrentUserPermissions] = useState([]);

  const onNavigationItemClick = (e) => {
    const selected = e.target.selected;
    if (!selected) {
      return;
    }
    handleSelectionChange(e.target.id);
  };

  const onSelectionChange = (evt: Ui5CustomEvent<HTMLElement, { item: React.ReactNode }>) => {
    const clickItemId = (evt.detail.item as HTMLElement).id;
    handleSelectionChange(clickItemId);
  };

  /* istanbul ignore next */
  const handleSelectionChange = (clickItemId: string) => {
    const { config } = props;
    if (!clickItemId) {
      return;
    }
    const testingLngCode = getURLParam(props.history.location.search, 'sap-ui-language');
    const testingLngParam = testingLngCode ? `?sap-ui-language=${testingLngCode}` : '';
    if (clickItemId === 'home' && !homeDisabled) {
      setSelectedId('home');
      props.history.push(`/${testingLngParam}`);
    } else {
      const cfg = getConfigValueByProp(config, 'id', clickItemId, 'router');
      if (cfg && cfg.router && cfg.router !== '#' && !cfg.disabled) {
        try {
          props.history.push(cfg.router + testingLngParam);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const buildSideNavTree = (sideNavList: any[]) => {
    if (!sideNavList || !Array.isArray(sideNavList)) {
      return [];
    }

    let haveParentSidenavItems: any[] = [];
    let newSidenavItems: any[] = [];

    for (let tmpSidenav of sideNavList) {
      let subItems = tmpSidenav.items;
      if (Array.isArray(subItems) && subItems.length > 0) {
        subItems.forEach((e) => {
          if (e?.parentId) {
            haveParentSidenavItems.push(e);
          }
        });

        subItems = subItems.filter((s) => !s?.parentId);
        tmpSidenav.items = subItems;
      }

      if (tmpSidenav?.parentId) {
        haveParentSidenavItems.push(tmpSidenav);
        continue;
      }

      newSidenavItems.push(tmpSidenav);
    }

    // Insert haveParentSidenavItems into newSidenavItems
    for (let tmpItem of haveParentSidenavItems) {
      for (let parentSidenav of newSidenavItems) {
        if (parentSidenav.id === tmpItem.parentId) {
          tmpItem.icon = null;
          parentSidenav.items.push(tmpItem);
          break;
        }
      }
    }

    return newSidenavItems;
  };

  /**
   * update the SideNav item in config.json to control the Menu Visibility and Permisiion
   * to control by FF: add "featureToggles":["xxxx"]
   * to control by Config: add "globalConfigs":["FundToggle","FundEnableFrom"]
   *  to control by roles: same as before, no changes
   */
  const checkMenuPermission = (item) => {
    return (
      checkGlobalConfigs(item) &&
      checkFeaturetoggles(item) &&
      checkPermissions(item, currentUserPermissions)
    );
  };

  const getSideNavList = () => {
    const { config } = props;
    let sideNavList: any[] = [];
    let filterUnFFSideNavList: any[] = [];
    console.log('side', config);
    if (config && config.components) {
      config.components.forEach((comp) => {
        const { sidenav } = comp.config;
        if (sidenav && Array.isArray(sidenav) && sidenav.length > 0) {
          sideNavList = [...sideNavList, ...sidenav];
        }
      });
    }

    const tmpSideNavList = buildSideNavTree(sideNavList);
    if (Array.isArray(tmpSideNavList) && tmpSideNavList.length > 0) {
      sideNavList = tmpSideNavList
        .filter(
          (i) =>
            i.items?.filter((c) => checkMenuPermission(c)).length > 0 ||
            (checkMenuPermission(i) && !!i.router),
        )
        .map((e) => {
          const tmpItems = e.items?.sort((a, b) => a.order - b.order);
          if (Array.isArray(tmpItems) && tmpItems.length > 0) {
            e.items = Array.from(new Set(e.items));
          }

          return e;
        })
        .sort((a, b) => a.order - b.order);
    }

    return sideNavList;
  };

  /* istanbul ignore next */
  const renderNavItems = () =>
    getSideNavList().map((sideNav: Sidenav) => {
      if (sideNav.items && Array.isArray(sideNav.items) && sideNav.items.length) {
        return (
          <SideNavigationItem
            onClick={onNavigationItemClick}
            key={sideNav.id}
            id={sideNav.id}
            className={
              isDisable(sideNav) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            text={t(sideNav.text)}
            icon={sideNav.icon}
            title={t(sideNav.text)}
            expanded={!!sideNav.items.find((child) => child.id === selectedId)}
          >
            {sideNav.items
              .filter((sideNavItem) => checkMenuPermission(sideNavItem))
              .map((sideNavItem) => (
                <SideNavigationSubItem
                  onClick={onNavigationItemClick}
                  key={sideNavItem.id}
                  className={
                    isDisable(sideNavItem)
                      ? classes.disabled
                      : `${classes.enabled} ${classes.customized}`
                  }
                  id={sideNavItem.id}
                  text={t(sideNavItem.text)}
                  icon={sideNavItem.icon}
                  title={t(sideNavItem.text)}
                  selected={sideNavItem.id === selectedId}
                  data-id={sideNavItem.id}
                />
              ))}
          </SideNavigationItem>
        );
      } else {
        return (
          <SideNavigationItem
            onClick={onNavigationItemClick}
            key={sideNav.id}
            className={
              isDisable(sideNav) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            // style={{ backgroundColor: 'red !important' }}
            id={sideNav.id}
            text={t(sideNav.text)}
            icon={sideNav.icon}
            selected={sideNav.id === selectedId}
            title={t(sideNav.text)}
          />
        );
      }
    });

  useEffect(() => {
    const { config } = props;
    let { pathname } = location;

    if (pathname !== '/') {
      const cfg = getConfigValueByProp(config, 'router', pathname, 'id');
      if (cfg?.id) {
        setSelectedId(cfg.id);
      } else {
        setSelectedId('home');
      }
    }
  }, [props]);

  useEffect(() => {
    const permissions = getConfig('CurrentUserPermissions') || [];
    setCurrentUserPermissions(permissions);
  }, []);

  return (
    <SideNavigationObserver
      className={classes.sideNavigation}
      style={{ height: '100%', fontSize: '14px' }}
      collapsed={!showMenu}
      selectedId={selectedId}
      noIcons={false}
      onSelectionChange={onSelectionChange}
      // footerItems={[
      //   <SideNavigationItem id="footer1" text="Legal Information" icon="compare" />,
      //   <SideNavigationItem id="footer2" text="Useful Links" icon="chain-link" />,
      // ]}
    >
      {renderNavItems()}
    </SideNavigationObserver>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
