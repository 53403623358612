import React, { useState, useMemo, useCallback, useLayoutEffect, useEffect } from 'react';
import eurekaMgrs from '@eureka/ui-managers';
import { useLocation, matchPath } from 'react-router-dom';
import NoAccessView from './NoAccessView';
import { checkFeaturetoggles, checkGlobalConfigs, checkPermissions } from './Utils';
import { DefaultLayout } from 'src/features/common';

const { getConfig } = eurekaMgrs.ConfigManager;

const RoutePermissionWrapper = ({ history, config, settings, user, eventBus, children }) => {
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);

  const allRouterConfigs = useMemo(
    () => [
      ...config.components
        .flatMap((component) => component?.config?.sidenav || [])
        .flatMap((sidenav) => (sidenav?.items?.length ? sidenav.items : sidenav)),
      ...config.components.flatMap((component) => component?.config?.permissionRouters || []),
    ],
    [config],
  );

  const getMatchedItem = useCallback(
    (pathname) => {
      for (let item of allRouterConfigs) {
        if (matchPath({ path: item.router, caseSensitive: false, end: true }, pathname))
          return item;
      }
      return null;
    },
    [allRouterConfigs],
  );

  useLayoutEffect(() => {
    const pathname = location.pathname;
    setHasPermission(true);
    const matchedItem = getMatchedItem(location.pathname);
    const currentUserPermissions = getConfig('CurrentUserPermissions') || [];
    if (pathname.toLowerCase() === '/' || pathname.toLowerCase() === '/login') {
      setHasPermission(true);
    } else if (!matchedItem) {
      //TODO, add all router config into config json of each MFE, to provide deep linking permission check.
      // currently pass cheeck
      // history.push('/');
      setHasPermission(true);
    } else {
      setHasPermission(
        checkFeaturetoggles(matchedItem) &&
          checkGlobalConfigs(matchedItem) &&
          checkPermissions(matchedItem, currentUserPermissions),
      );
    }
  }, [location, allRouterConfigs, getMatchedItem]);

  useEffect(() => {
    eventBus.on('on-no-permission', () => {
      setHasPermission(false);
    });
    return () => {
      eventBus.die('on-no-permission');
    };
  }, [eventBus]);

  return hasPermission ? (
    children
  ) : (
    <DefaultLayout match={{}} history={history} config={config} user={user} settings={settings}>
      <NoAccessView />
    </DefaultLayout>
  );
};

export default RoutePermissionWrapper;
