import React, { useEffect, useState } from 'react';
import { Select, Option, Label, Text, Title } from 'src/common/ui5dependencies';
import { SegmentedButton, SegmentedButtonItem } from 'src/common/ui5dependencies';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as actions from '../redux/actions';
import { fetchTimeZone } from '../redux/actions';
import {
  DateFormatOptions,
  DateFormatTexts,
  TimeFormatOptions,
  TimeFormatTexts,
  TimeFormatTextsDefualts,
  NumberFormatObject,
} from './options';
import { useTranslation } from './eureka';
import { UserProfileState } from 'src/types';

dayjs.extend(utc);
dayjs.extend(timezone);

const formItemStyle: React.CSSProperties = {
  margin: '15px auto',
  display: 'flex',
  alignItems: 'center',
};

const labelStyle: React.CSSProperties = {
  width: '120px',
  marginRight: '8px',
  textAlign: 'right',
};

const borderStyle = '1px solid #e8e8e8';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: UserProfileState;
    languages: languages[];
    onDataPropChange: (value: string, propName: string) => void;
    thousandError: string;
    setThousandError: any;
    lastChangePlace: string;
    setLastChangePlace: any;
  };

interface languages {
  language: string;
  languageCode: string;
  displayLanguage: {
    nativeLanguage: string;
    englishLanguage: string;
  };
  displayLanguageCode: string;
}

const LanguageAndRegion: React.FC<Props> = ({
  data,
  languages,
  actions,
  common,
  onDataPropChange,
  thousandError,
  setThousandError,
  lastChangePlace,
  setLastChangePlace,
}) => {
  const { t } = useTranslation('shell');
  const { fetchTimeZone } = actions;
  const { timezone } = common;
  const [decimalFormat, setDecimalFormat] = useState('.');
  const [thousandsFormat, setThousandsFormat] = useState(',');

  useEffect(() => {
    if (!timezone) {
      fetchTimeZone();
    }
  }, []);

  useEffect(() => {
    handleNumberFormat('decimal', data.decimalFormat);
    handleNumberFormat('thousandsFormat', data.thousandsFormat);
  }, [data.decimalFormat]);

  const handleNumberFormat = (format, id) => {
    let formatSymbol = ',';
    if (id === NumberFormatObject[0].id) {
      formatSymbol = '.';
    } else if (id === NumberFormatObject[2].id) {
      formatSymbol = ' ';
    } else if (id === NumberFormatObject[3].id) {
      formatSymbol = '‘';
    }
    if (format === 'decimal') {
      setDecimalFormat(formatSymbol);
    } else {
      setThousandsFormat(formatSymbol);
    }
  };

  return (
    <div style={{ padding: '1.5rem' }}>
      <Title
        level="H3"
        size="H3"
        style={{
          marginTop: '16px',
          marginBottom: '32px',
        }}
      >
        {t('UserProfile_RegionalSettings', 'Regional Settings', { type: 'tit', desc: '' })}
      </Title>
      <div style={{ marginLeft: '46px' }}>
        <div style={{ ...formItemStyle, margin: '0 auto 15px' }}>
          <Label showColon style={labelStyle}>
            {t('UserProfile_Language', 'Language', { type: 'tit', desc: '' })}
          </Label>
          <Select
            onChange={(evt) => {
              const lang = evt?.detail?.selectedOption?.dataset?.id;
              /* istanbul ignore next */
              lang && onDataPropChange(lang, 'language');
            }}
            id="language"
            data-testid="user-profile-select-language"
            style={{ width: '251px' }}
          >
            {languages.map((language: languages) => {
              return (
                <Option
                  key={language.languageCode}
                  data-id={language.languageCode}
                  value={language.languageCode}
                  selected={language.languageCode === data.language}
                >
                  {`${language.displayLanguage.nativeLanguage} (
                  ${language.language}
                )`}
                </Option>
              );
            })}
          </Select>
        </div>
        <div style={formItemStyle}>
          <Label showColon style={labelStyle}>
            {t('UserProfile_TimeZone', 'Time Zone', { type: 'tit', desc: '' })}
          </Label>
          <Select
            data-testid="user-profile-time-zone"
            onChange={(evt) => {
              const timezone = evt?.detail?.selectedOption?.dataset?.id;
              /* istanbul ignore next */
              timezone && onDataPropChange(timezone, 'profileTimeZone');
            }}
            id="timeZone"
            style={{ width: '251px' }}
          >
            {timezone?.map((timeZone, index) => {
              return (
                <Option
                  key={timeZone.timezone}
                  data-id={timeZone.timezone}
                  value={timeZone.timezone}
                  selected={timeZone.timezone === data.profileTimeZone}
                >
                  {timeZone.displayName}
                </Option>
              );
            })}
          </Select>
        </div>
        <div style={formItemStyle}>
          <Label showColon style={labelStyle}>
            {t('UserProfile_DateFormat', 'Date Format', { type: 'tit', desc: '' })}
          </Label>
          <Select
            data-testid="user-profile-date-format"
            onChange={(evt) => {
              const dateFormat = evt?.detail?.selectedOption?.dataset?.id;
              /* istanbul ignore next */
              dateFormat && onDataPropChange(dateFormat, 'dateFormat');
            }}
            id="dateFormat"
            style={{ width: '251px' }}
          >
            {DateFormatOptions.map((dateFormat, index) => {
              return (
                <Option
                  key={dateFormat}
                  data-id={dateFormat}
                  value={dateFormat}
                  selected={dateFormat === data.dateFormat}
                >
                  {DateFormatTexts[index]}
                </Option>
              );
            })}
          </Select>
        </div>
        <div style={formItemStyle}>
          <Label showColon style={labelStyle}>
            {t('UserProfile_TimeFormat', 'Time Format', { type: 'tit', desc: '' })}
          </Label>
          <SegmentedButton
            data-testid="user-profile-time-format"
            onSelectionChange={(evt) => {
              const selectedItems = evt?.detail?.selectedItems;
              if (selectedItems && selectedItems?.length > 0) {
                onDataPropChange(selectedItems[0]?.id, 'timeFormat');
              }
            }}
          >
            {TimeFormatOptions.map((timeFormat, index) => (
              <SegmentedButtonItem
                key={timeFormat}
                id={timeFormat}
                selected={timeFormat === data.timeFormat}
                data-testid={`time-format-${timeFormat}`}
              >
                {t(TimeFormatTexts[index], TimeFormatTextsDefualts[index], {
                  type: 'but',
                  desc: '',
                })}
              </SegmentedButtonItem>
            ))}
          </SegmentedButton>
        </div>
        <div style={formItemStyle}>
          <Label showColon style={labelStyle}>
            {t('UserProfile_DecimalFormat', 'Decimal Format', { type: 'tit', desc: '' })}
          </Label>
          <Select
            data-testid="user-profile-decimal-format"
            onChange={(evt) => {
              const dateFormat = evt?.detail?.selectedOption?.dataset?.id;
              /* istanbul ignore next */
              setLastChangePlace('decimalFormat');
              thousandError && setThousandError('');
              handleNumberFormat('decimal', dateFormat);
              dateFormat && onDataPropChange(dateFormat, 'decimalFormat');
            }}
            id="dateFormat"
            style={{ width: '251px' }}
            valueState={
              !!thousandError && lastChangePlace === 'decimalFormat' ? 'Negative' : 'None'
            }
            valueStateMessage={<div>{thousandError}</div>}
            onClose={() => {
              setThousandError('');
            }}
          >
            {NumberFormatObject.slice(0, 2).map((numberFormat) => {
              return (
                <Option
                  key={numberFormat.id}
                  data-id={numberFormat.id}
                  value={numberFormat.id}
                  selected={numberFormat.id === data.decimalFormat}
                >
                  {t(numberFormat.text, numberFormat.defaultText, { type: 'msg', desc: '' })}{' '}
                  {numberFormat.formatSymbol}
                </Option>
              );
            })}
          </Select>
        </div>
        <div style={formItemStyle}>
          <Label showColon style={labelStyle}>
            {t('UserProfile_ThousandsFormat', 'Thousands Format', { type: 'tit', desc: '' })}
          </Label>
          <Select
            data-testid="user-profile-thousands-format"
            onChange={(evt) => {
              const dateFormat = evt?.detail?.selectedOption?.dataset?.id;
              /* istanbul ignore next */
              setLastChangePlace('thousandsFormat');
              thousandError && setThousandError('');
              handleNumberFormat('thousands', dateFormat);
              dateFormat && onDataPropChange(dateFormat, 'thousandsFormat');
            }}
            id="dateFormat"
            style={{ width: '251px' }}
            valueState={
              !!thousandError && lastChangePlace === 'thousandsFormat' ? 'Negative' : 'None'
            }
            valueStateMessage={<div>{thousandError}</div>}
            onClose={() => {
              setThousandError('');
            }}
          >
            {NumberFormatObject.map((numberFormat) => {
              return (
                <Option
                  key={numberFormat.id}
                  data-id={numberFormat.id}
                  value={numberFormat.id}
                  selected={numberFormat.id === data.thousandsFormat}
                >
                  {t(numberFormat.text, numberFormat.defaultText, { type: 'msg', desc: '' })}{' '}
                  {numberFormat.formatSymbol}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>

      <div style={{ height: '38px' }}></div>
      <div
        style={{
          width: '608px',
          position: 'absolute',
          right: 0,
          borderTop: borderStyle,
          padding: '1rem 1.5rem',
          boxSizing: 'border-box',
        }}
      >
        <Title
          level="H5"
          size="H5"
          style={{
            marginTop: '16px',
            marginBottom: '32px',
          }}
        >
          {t('UserProfile_Format_Preview', 'Format Preview', { type: 'tit', desc: '' })}
        </Title>
        <div style={{ marginLeft: '46px' }}>
          <div style={formItemStyle}>
            <Label showColon style={labelStyle}>
              {t('UserProfile_DateFormat_Text', 'Date', {
                type: 'tit',
                desc: 'Label text for date format sample',
              })}
            </Label>
            <Text>
              {data.profileTimeZone &&
                dayjs().utc().tz(data.profileTimeZone).format(data.dateFormat)}
            </Text>
          </div>
          <div style={formItemStyle}>
            <Label showColon style={labelStyle}>
              {t('UserProfile_TimeFormat_Text', 'Time', {
                type: 'tit',
                desc: 'Label text for time format sample',
              })}
            </Label>
            {
              <Text>
                {data.profileTimeZone &&
                  (data.timeFormat === 'TWENTYFOUR_HOUR'
                    ? dayjs().utc().tz(data.profileTimeZone).format('HH:mm')
                    : dayjs().utc().tz(data.profileTimeZone).format('hh:mm a'))}
              </Text>
            }
          </div>
          <div style={formItemStyle}>
            <Label showColon style={labelStyle}>
              {t('UserProfile_NumberFormat_Text', 'Number', {
                type: 'tit',
                desc: 'Label text for number format sample',
              })}
            </Label>
            {
              <Text>
                100{thousandsFormat}000{decimalFormat}00
              </Text>
            }
          </div>
        </div>
      </div>
      <div style={{ height: '210px' }}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, fetchTimeZone }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageAndRegion);
