export const DateFormatOptions = [
  'DD.MM.YYYY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  // 'MM/DD/YY',
  'MM DD, YYYY',
  'YYYY-MM-DD',
];
export const DateFormatTexts = [
  'DD.MM.YYYY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  // 'MM/DD/YY',
  'MM DD, YYYY',
  'YYYY-MM-DD',
];
export const TimeFormatOptions = ['TWELVE_HOUR', 'TWENTYFOUR_HOUR'];
export const TimeFormatTexts = ['UserProfile_TwelveHour', 'UserProfile_TwentyFourHour'];
export const TimeFormatTextsDefualts = ['12 Hours', '24 Hours'];

export const NumberFormatObject = [
  { id: 'PERIOD', text: 'UserProfile_Decimal_Period', defaultText: 'Period', formatSymbol: '(.)' },
  { id: 'COMMA', text: 'UserProfile_Decimal_Comma', defaultText: 'Comma', formatSymbol: '(,)' },
  { id: 'SPACE', text: 'UserProfile_Decimal_Space', defaultText: 'Space', formatSymbol: '( )' },
  {
    id: 'APOSTROPHE',
    text: 'UserProfile_Decimal_Apostrophe',
    defaultText: 'Apostrophe',
    formatSymbol: '(‘)',
  },
];
