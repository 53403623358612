import { ReactElement, ReactNode } from 'react';
import eureka from 'eureka';
import { IllustratedMessage, Title, Text } from 'src/common/ui5dependencies';
import '@ui5/webcomponents-fiori/dist/illustrations/ErrorScreen.js';
const { useTranslation } = eureka.I18nProvider;

const NoAccessView = () => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="No_Access_View"
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <IllustratedMessage
        name="ErrorScreen"
        title={
          <Title level="H2" size="H2">
            {t('NoAccess_Title', 'Access Denied', {
              type: 'tit',
              desc: 'NoAccess Title',
            })}
          </Title>
        }
        subtitle={
          <Text
            style={{
              fontSize: '16px',
              width: '450px',
            }}
          >
            {t(
              'NoAccess_SubTitle',
              'You do not have the necessary permissions to view this content.If you need access, please contact your administrator.',
              {
                type: 'tit',
                desc: 'NoAccess SubTitle',
              },
            )}
          </Text>
        }
      />
    </div>
  );
};

export default NoAccessView;
