import eurekaMgrs from '@eureka/ui-managers';
import { getRandom } from '../features/common/Utils';
import { cloneDeep } from 'lodash';
import { ConfigJson, Sidenav, SidenavItem, isSidenav } from 'src/types';

const { getThemeList } = eurekaMgrs.AppearanceManager;
const { getFeatureToggle, getConfig } = eurekaMgrs.ConfigManager;

export const loadCSS = (
  dom: Document,
  assetName: string,
  host: string,
  name: string,
  filePath: string,
  hash: string,
) => {
  const buildCssLink = (resolve, reject) => {
    const styleId = `${assetName}-style-${name.toLowerCase()}`;
    const style = dom.getElementById(styleId);
    if (!style) {
      const cssLink = dom.createElement('link');
      cssLink.id = styleId;
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      cssLink.crossOrigin = 'anonymous';
      cssLink.href = `${host}${filePath}?random=${getRandom()}`;
      if (hash) {
        cssLink.integrity = hash;
      }
      cssLink.onload = () => resolve();
      cssLink.onerror = (err) => reject(err);
      dom.head.appendChild(cssLink);
    } else {
      resolve();
    }
  };

  return new Promise((resolve, reject) => {
    buildCssLink(resolve, reject);
  });
};

export function getURLParam(url: string, name: string) {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(name);
}

// 1Q = en-US-saptrc;
// 2Q = en-US-sappsd;
// 3Q = en-US-saprigi;
export const TestingLocales = {
  '1Q': 'en-US-saptrc',
  '2Q': 'en-US-sappsd',
  '3Q': 'en-US-saprigi',
};

export const constructPermissions = (
  rolePermission: {
    permissionItem: { items: { accesses: { accessName: string }[]; authItemValue: string }[] }[];
  }[],
) => {
  const permissions: string[] = [];
  (rolePermission || [])
    .map((r) => r.permissionItem)
    .forEach((p) => {
      p.forEach((o) => {
        o.items.forEach((i) => {
          i.accesses.forEach((access) => {
            const authItem = `${i.authItemValue}.${access.accessName}`.toUpperCase();
            if (!permissions.includes(authItem)) {
              permissions.push(authItem);
            }
          });
        });
      });
    });
  return permissions;
};

export const setDocumentLang = (doc: Document, langCode: string) => {
  doc && (doc.documentElement.lang = langCode);
};

/**
 * function to remove a MFE Component from Side-nav list if FF is disabled
 * @param {Array}: input array containing list of components/MFEs
 * @param {String}: name of components/MFE to be shown/hidden
 * @param {Boolean}: feature flag
 * @returns {Array}: output array containing list of components/MFEs to be displayed in side-nav
 * */
/* istanbul ignore next */
export function removeFeatureFlaggedComponent(
  componentList: Sidenav[],
  componentName: string,
  featureFlag: boolean,
) {
  if (!componentName || featureFlag === undefined) {
    return componentList;
  }
  // if FF is disabled, remove component from the list and return remaining list
  if (!featureFlag) {
    componentList = cloneDeep(componentList);
    const components = componentName.split('.');
    let currentComponents: Sidenav[] | SidenavItem[] = componentList;
    let index = -1;
    for (let i = 0; i < components.length; i++) {
      const subComponentName = components[i];
      index = currentComponents.findIndex((component) => component.id === subComponentName);
      if (index < 0) {
        return componentList;
      }
      if (i !== components.length - 1) {
        // TODO: ???
        const com = currentComponents[index];
        if (isSidenav(com)) {
          currentComponents = com.items;
        }
      }
    }
    currentComponents.splice(index, 1);
  }
  return componentList;
}

export const checkPermissions = (sideNav: SidenavItem, currentUserPermissions: string[]) => {
  // eslint-disable-next-line no-prototype-builtins
  if (sideNav.hasOwnProperty('skipPermission') && sideNav.skipPermission) {
    return true;
  }
  if (sideNav.permissions) {
    const permissions = Array.isArray(sideNav.permissions)
      ? sideNav.permissions.map((p) => p.toUpperCase())
      : [];
    if (sideNav.conjunction && sideNav.conjunction === 'OR') {
      return permissions.length > 0 && permissions.some((p) => currentUserPermissions.includes(p));
    }
    return permissions.length > 0 && permissions.every((p) => currentUserPermissions.includes(p));
  }
  return false;
};

export const checkFeaturetoggles = (item) => {
  if (!Array.isArray(item.featureToggles) || item.featureToggles.length === 0) {
    return true;
  }
  return item.featureToggles.every((featureToggle) => getFeatureToggle(featureToggle));
};

export const checkGlobalConfigs = (item) => {
  if (!Array.isArray(item.globalConfigs) || item.globalConfigs.length === 0) {
    return true;
  }
  return item.globalConfigs.every((config) => !!getConfig(config));
};

/**
 * Return with a filted list items(componentList) for menu, which feature flags operation done
 * We can manipulate configuration return with deep cloned here
 * @param {} listItems
 */
export const filterWithFeatureFlags = (config: ConfigJson) => {
  if (config?.components?.length > 0) {
    let newConfig = cloneDeep(config);
    // TODO:
    // newConfig = handleWithFeatureFlagOME6869(newConfig);
    return newConfig;
  }
  return config;
};

// css from https://wiki.wdf.sap.corp/wiki/pages/viewpage.action?pageId=2697898341
export const THEME_LIST = [
  {
    name: 'sap_horizon',
    displayName: 'SAP Morning Horizon',
    themeId: 'sap_horizon',
    icon: '',
    style: {
      backgroundColor: '#fff', // var(--sapList_FooterBackground)
      border: '1px solid #758ca4', // var(--sapContent_ForegroundBorderColor)
      // boxShadow: 'inset 0 0 2px #758ca4',
    },
    order: '10',
  },
  {
    name: 'sap_horizon_dark',
    displayName: 'SAP Evening Horizon',
    themeId: 'sap_horizon_dark',
    icon: '',
    style: {
      backgroundColor: '#1d2d3e', // var(--sapContent_IconColor)
      border: '1px solid #758ca4', // var(--sapContent_ForegroundBorderColor)
      // boxShadow: 'inset 0 0 2px #a9b4be',
    },
    order: '20',
  },
  {
    name: 'sap_horizon_hcw',
    displayName: 'SAP High Contrast White',
    themeId: 'sap_horizon_hcw',
    icon: '',
    style: {
      backgroundColor: '#fff', // var(--sapContent_ContrastIconColor)
      border: '1px solid #000', // var(--sapBlockLayer_Background)
    },
    order: '30',
  },
  {
    name: 'sap_horizon_hcb',
    displayName: 'SAP High Contrast Black',
    themeId: 'sap_horizon_hcb',
    icon: '',
    style: {
      backgroundColor: '#000', // var(--sapBlockLayer_Background)
      border: '1px solid #758ca4', // var(--sapContent_ForegroundBorderColor)
    },
    order: '40',
  },
];

export const getThemeById = (themeId: string) => {
  return (getThemeList() || []).find((ele) => ele.themeId === themeId);
};
